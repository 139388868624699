import React from "react";
import Mainloader from '../assets/Bigloader.png'; 
import './Loader.css'; // Import the CSS file
import { ColorRing } from 'react-loader-spinner';




export const Pageloader = ()=> {

       return(   
           <div className=" w-[100%] text-bold bg-black text-white rounded-lg border-2 border-[#FFD700] py-[6rem] px-[2rem] ">
        <div className='flex flex-col items-center justify-center w-[100%] max-lg:hidden'>
       <img  className='mb-[-10px] rounded-lg' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712307486/Campusbuy/Rectangle_322_xnb1so.png' width={600} height={70} alt='ball'/>
       <div className='flex loader'>
      <img className=' front' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250377/Campusbuy/Group_221_imejvm.png' width={55} height={55} alt='front'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250229/Campusbuy/Group_222_owexvh.png' width={55} height={55} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250235/Campusbuy/Group_223_jutas9.png' width={55} height={55} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250245/Campusbuy/Group_224_nr67hs.png' width={55} height={55} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250246/Campusbuy/Group_225_rkuv5t.png' width={55} height={55} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250247/Campusbuy/Group_226_omvdnl.png' width={55} height={55} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250247/Campusbuy/Group_227_v91opq.png' width={55} height={55} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250260/Campusbuy/Group_228_lhqakh.png' width={55} height={55} alt='ball'/>
      <img  className=' back' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250261/Campusbuy/Group_229_twe4yf.png' width={55} height={55} alt='back'/>
    </div>
        </div>


        <div className='hidden max-lg:flex flex-col items-center justify-center w-[100%] pb-[12rem]'>
       <img  className='mb-[-10px] rounded-lg' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712307486/Campusbuy/Rectangle_322_xnb1so.png' width={500} height={60} alt='ball'/>
       <div className='flex loader'>
      <img className=' front' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250377/Campusbuy/Group_221_imejvm.png' width={30} height={30} alt='front'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250229/Campusbuy/Group_222_owexvh.png' width={30} height={30} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250235/Campusbuy/Group_223_jutas9.png' width={30} height={30} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250245/Campusbuy/Group_224_nr67hs.png' width={30} height={30} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250246/Campusbuy/Group_225_rkuv5t.png' width={30} height={30} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250247/Campusbuy/Group_226_omvdnl.png' width={30} height={30} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250247/Campusbuy/Group_227_v91opq.png' width={30} height={30} alt='ball'/>
      <img  className='' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250260/Campusbuy/Group_228_lhqakh.png' width={30} height={30} alt='ball'/>
      <img  className=' back' src='https://res.cloudinary.com/dneejvhch/image/upload/v1712250261/Campusbuy/Group_229_twe4yf.png' width={30} height={30} alt='back'/>
    </div>
        </div>
      </div>
       )
    }



    
    export const Bigloader = () => {
      return (
        <div className='bigloader-container bg-black rounded-lg p-4 w-[50%] max-lg:w-[100%]'>
          <div className='spinner-container'>
            <div className='spinner'>
              <div className='spinner-dot'></div>
              <div className='spinner-dot'></div>
              <div className='spinner-dot'></div>
              <div className='spinner-dot'></div>
            </div>
            <div className='central-image'>
              <img src={Mainloader} alt="Main loader"/>
            </div>
          </div>
          <strong className='text-lg text-white my-4'>Please wait ...</strong>
        </div>
      )
    }
    export const Mediumloader = () => {
      return (
        <div className='bigloader-container bg-black rounded-lg p-4 w-[100%] border-2 border-[#FFD700]'>
          <p style={{ color: 'white', marginTop: '10px' }}>Please wait. Logging in...</p>
              <div className='w-[70%]'>
                <ColorRing 
                  visible={true}
                  height="100"
                  width="100"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#FFB04A', '#FFB04A', '#FFB04A', '#FFB04A', '#FFB04A']}
                />
              </div>
          <strong className='text-lg text-white my-4'>Please wait ...</strong>
        </div>
      )
    }
    export const Mediumloaders = () => {
      return (
        <div className='bigloader-container bg-black rounded-lg p-4 w-[100%] border-2 border-[#FFD700]'>
          <p style={{ color: 'white', marginTop: '10px' }}>Please wait...</p>
              <div className='w-[70%]'>
                <ColorRing 
                  visible={true}
                  height="100"
                  width="100"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#FFB04A', '#FFB04A', '#FFB04A', '#FFB04A', '#FFB04A']}
                />
              </div>
          <strong className='text-lg text-white my-4'>Please wait ...</strong>
        </div>
      )
    }
    export const Buttonloader = () => {
      return (
        <div className='button-loading'>
                <ColorRing 
                  visible={true}
                  height="30"
                  width="30"
                  ariaLabel="button-loading"
                  wrapperStyle={{}}
                  wrapperClass="button-loader"
                  colors={['#FFB04A', '#FFB04A', '#FFB04A', '#FFB04A', '#FFB04A']}
                />
              </div>
      )
    }
    export const Buttoncopy = () => {
      return (
        <button className='p-1 bg-[#FC6600]  rounded-lg text-black'>Copied!</button>
      )
  }


  
    
    