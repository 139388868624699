import React from 'react';
import { Yourproductscards } from '../../Components/Productcards';
import { Yourproductsmobilecards } from '../../Components/Productcards';

const Yourproducts = () => {
  return (
    <div>
      <div className='max-lg:hidden  w-[100%]'>
      <div className='text-2xl text-bold my-4  '>
        <h1>Yourproducts</h1>
      </div>
      <div className='flex flex-wrap justify-between items-center w-[100%]'>
        <Yourproductscards  />
      </div>
      </div>

      <div className='hidden max-lg:block'>
      <div className='text-2xl text-bold my-4  '>
        <h1>Yourproducts</h1>
      </div>
      <div className='flex flex-wrap justify-between items-center w-[100%] '>
        <Yourproductsmobilecards  />
      </div>

      </div>
    </div>
  );
}

export default Yourproducts;
