import React, { useState, useEffect } from 'react';
import { Mystorecard } from '../../Components/Productcards';
import { Mobilemystorecard } from '../../Components/Productcards';
import { Buttoncopy } from '../../Modals/Loaders';

const Mystore = () => {
  const [link, setLink] = useState('');
  const [showModal, setShowModal] = useState(false);

  const storedUserData = JSON.parse(localStorage.getItem('userData')); 

  useEffect(() => {
    setLink(`https://campusify.net/store/${storedUserData.id? storedUserData.id : storedUserData._id}`);
  }, [storedUserData._id]);

  const handleStoreLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2000); // Hide modal after 4 seconds
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const backgroundImageStyle = {
    backgroundImage: `url('https://res.cloudinary.com/dneejvhch/image/upload/v1708949354/Campusbuy/Group_220_opz2ug.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "8rem",
    color: "white",
  };
  

  return (
    <div>
      
      <div className='max-lg:hidden w-[100%]'>
        <div className=' trending-products flex items-center justify-start gap-[4rem]'>
          <button onClick={handleStoreLink} className='p-2 border-2 border-black bg-[#FDD700] rounded-lg text-sm text-black'>{showModal ? (<div><Buttoncopy /></div>) : ('Store Link')}</button>
          <strong className='text-2xl'>Seller's Store</strong>
        </div>
        <div className='text-2xl text-bold my-4 text-center'>
          <strong>All Products in {storedUserData.firstname}'s Store</strong>
        </div>
        
        <div className='flex flex-wrap justify-between items-center w-[100%]'>
          <Mystorecard />
        </div>
      </div>
      
      <div className='hidden max-lg:block container'>
        <div style={backgroundImageStyle} className='text-2xl text-bold my-4 text-center trending-products'>
          <strong>Seller's Store</strong>
        </div>
        <div className='text-2xl text-bold my-4 text-center'>
          <strong>All Products in {storedUserData.firstname}'s Store</strong>
        </div>
        
        <div className='flex flex-wrap justify-between items-center w-[100%]'>
          <Mobilemystorecard />
        </div>
      </div>
    </div>
  );
}

export default Mystore;
