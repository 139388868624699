import React, { useState, useEffect } from 'react';
import {  useSelector } from 'react-redux';
import { NavLink, Link, useNavigate, useParams } from 'react-router-dom';
import { Loginmodal } from '../Modals/Pages/Signin.js';
import { Tokenerrormodal, Signedinmodal } from '../Modals/Forms.js';
import axios from 'axios';
import { Callbackmodals, Messagemodals, Trendingmessagemodals } from '../Components/Productmodals';
import { ContactSeller, Messagesent, Messagenotsent } from '../Modals/Pages/Message.js';
import { Safetytips } from '../Modals/General/Safetytips.js';
import { Addedtowishlist, Notaddedtowishlist } from '../Modals/Pages/Products.js'; 
import { Callbacksent, Callbacknotsent } from '../Modals/Pages/Callback.js';
import { Buttonloader } from '../Modals/Loaders.js';
import {Helmet} from 'react-helmet';
import { Trendingproductscards, Productcardreuseable, Mobileproductcardreuseable, Otherproductcard, Mobileotherproductcard } from '../Components/Productcards';
import { TbCurrencyNaira } from 'react-icons/tb';
import { MdCancel } from 'react-icons/md';
import { Pricetips, Purchasetips, Bulkgoodstips } from '../Modals/Tips';
import{ Trendingproducts, Sellerproducts } from '../Components/Trendingproducts.js';
// import { Mediumadlink1, Mediumadlink2, Mediumadlink3, Smalladlink2,Smalladlink1, Smalladlink3, Adlink1, Adlink2, Adlink3 } from '../Modals/Adslinks.js';


export const Trendingproductpage = () => {
  const {id, title, name} = useParams();
  const navigate = useNavigate();
  const [buttonloading, setButtonloading] = useState(false);
  const [wishlist, setWishlist] = useState(false);
  const [wishlistfailed, setWishlistfailed] = useState(false);
  const [message, setMessage] = useState(false);
  const [callback, setCallback] = useState(false);
  const [safety, setSafety] = useState(false);
  const [seller, setSeller] = useState(false);
  const [messagesent, setMessagesent] = useState(false);
  const [messagenotsent, setMessagenotsent] = useState(false);
  const [callbacksent, setCallbacksent] = useState(false);
  const [callbacknotsent, setCallbacknotsent] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [loginSuccessModal, setLoginSuccessModal] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [noProduct, setNoProduct] = useState(false);
  const productId =   JSON.parse(localStorage.getItem('product'));

  const modals = {
    position: 'fixed',
    top: '10%',
    right: '10%',
    zIndex: '4000',
    width: '40%'
  }

  const openLoginModal= ()=>{
    setLoginModal(true)
  };
  const closeLoginModal= ()=>{
    setLoginModal(false)
  };
  const openLoginError= ()=>{
    setLoginError(true)
  };
  const closeLoginError= ()=>{
    setLoginError(false)
  };
  const openLoginSuccessModal= ()=>{
    setLoginSuccessModal(true)
  };
  const closeLoginSuccessModal= ()=>{
    setLoginSuccessModal(false)
  };

  const GET_PRODUCT_API_KEY = `https://campusbuy.onrender.com/getproduct/${id}`;
  const GET_MYSTORE_API_KEY = 'https://campusbuy.onrender.com/getmystore';

  const openButtonloading = () => {
    setButtonloading(true);
  };
  const closeButtonloading = () => {
    setButtonloading(false);
  };
  const openWishlist = () => {
    setWishlist(true);
  };
  const closeWishlist = () => {
    setWishlist(false);
  };
  const openWishlistfailed = () => {
    setWishlistfailed(true);
  };
  const closeWishlistfailed = () => {
    setWishlistfailed(false);
  };
  const openMessage = () => {
    setMessage(true);
    setCallback(false);
    setSeller(false);
  };
  const closeMessage = () => {
    setMessage(false);
  };
  const openCallback = () => {
    setCallback(true);
    setMessage(false);
    setSeller(false);
  };
  const closeCallback = () => {
    setCallback(false);
  };
  const openSafety = () => {
    setSafety(true);
    setSeller(false)
  };
  const closeSafety = () => {
    setSafety(false);
  };
  const openSeller = () => {
    setSeller(true);
    setSafety(false)
  };
  const closeSeller = () => {
    setSeller(false);
  };
  const openMessagesent = () => {
    setMessagesent(true);
  };
  const closeMessagesent = () => {
    setMessagesent(false);
  };
  const openMessagenotsent = () => {
    setMessagenotsent(true);
  };
  const closeMessagenotsent = () => {
    setMessagenotsent(false);
  };
  const openCallbacksent = () => {
    setCallbacksent(true);
  };
  const closeCallbacksent = () => {
    setCallbacksent(false);
  };
  const openCallbacknotsent = () => {
    setCallbacknotsent(true);
  };
  const closeCallbacknotsent = () => {
    setCallbacknotsent(false);
  };

  useEffect(() => {
    const Reload = async () => {
      try {
        if(id !== productId)
        {
      
        const response = await axios.get(GET_PRODUCT_API_KEY);
        const productData = response.data;
        localStorage.setItem('fullproduct', JSON.stringify(productData));
        localStorage.setItem('sellerproduct', JSON.stringify(productData));
        const sellerStore = await axios.get(`${GET_MYSTORE_API_KEY}/${response.data.sellerId}`);  
        await localStorage.setItem('sellerstore', JSON.stringify(sellerStore.data.products)); 
        navigate(`/trendingproductpage/${id}/${title||name}`);
        }
      } catch (error) {
        setNoProduct(true)
        console.error('Error fetching product data:', error);
      }
    };
    Reload();
  }, [id, navigate]);

  //  const [Productimage, setProductImage] = useState({
  //   images: [],
  //   title: '',
  //   description: ''
  // });

  // const Getimage = async () => {
  //   try {
  //     const response = await axios.get(GET_PRODUCT_API_KEY);
  //     const productData = response.data;
      
  //     // Store images and other product data in state
  //     setProductImage({
  //       images: productData.images || [],
  //       title: productData.title || title || name,
  //       description: productData.description || 'Buy and sell anything on Campus'
  //     });
      
  //     // Set the first image as selected image
  //     if (productData.images.length > 0) {
  //       setSelectedImage(productData.images[0]);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching product images:', error);
  //   }
  // };

  // // Fetch the product images when the component loads
  // useEffect(() => {
  //   Getimage();
  // }, [id]);
  const accessedToken =   JSON.parse(localStorage.getItem('userData'));
  
  const WISHLIST_API_KEY = 'https://campusbuy.onrender.com/addwishlist';
  const axiosInstance = axios.create({
    baseURL: 'https://campusbuy.onrender.com', // Replace with your API base URL
    headers: {
      'Content-Type': 'application/json',
      // Other default headers can be added here
    },
  });
  
  // Function to set JWT token in the headers
  const setAuthToken = (token) => {
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  };
  
  // Use this function to set the token when the user logs in or the token is available
  if (accessedToken && accessedToken.accessToken){
    setAuthToken(accessedToken.accessToken);
  }  
  // Now, you can use axiosInstance for your requests with the token included in the headers
  
//   const [activeAdslinkmedium, setActiveAdslinkmedium] = useState(null);

//   const closeAdslinkmedium = () => {
//       setActiveAdslinkmedium(false);
//   };
  
//   const adslinkmediumArray = ['Studyabroad', 'Auctionedgoods', 'Boostsales'];
//   const getRandomAdlinkmedium = () => adslinkmediumArray[Math.floor(Math.random() * adslinkmediumArray.length)];const selectAdslinkmedium = Math.floor(Math.random() * 3); 
//   useEffect(() => {
//       const displayRandomAdlinkmedium = () => {
//         const randomAdlinkmedium = getRandomAdlinkmedium();
//         setActiveAdslinkmedium(randomAdlinkmedium);
//       };
  
//       // Display a random tip 30 seconds after the page mounts
//       const displayAdlinkmediumTimeout = setTimeout(() => {
//          displayRandomAdlinkmedium();
//       }, 300000);

  
//       // Change the tip every 90 seconds
//       const changeAdlinkmediumInterval = setInterval(() => {
//         displayRandomAdlinkmedium();
//       }, 20000);
  
//       // Clear the interval when the component unmounts
//       return () => {
//         clearTimeout(displayAdlinkmediumTimeout);
//         clearInterval(changeAdlinkmediumInterval);
//       };
//     }, []); // Empty dependency array ensures that this effect runs only once on mount
  
//   let adlinkmediumComponent = null;
  
//     if (activeAdslinkmedium === 'Studyabroad') {
//       adlinkmediumComponent = <Mediumadlink1 closeAdslink={closeAdslinkmedium} />;
//     } else if (activeAdslinkmedium === 'Auctionedgoods') {
//       adlinkmediumComponent = <Mediumadlink2 closeAdslink={closeAdslinkmedium} />;
//     } else if (activeAdslinkmedium === 'Boostsales') {
//       adlinkmediumComponent = <Mediumadlink3 closeAdslink={closeAdslinkmedium} />;
//     }

//     const [activeAdslinksmall, setActiveAdslinksmall] = useState(null);

// const closeAdslinksmall = () => {
//     setActiveAdslinksmall(false);
// };

// const adslinksmallArray = ['Freeairtime', 'Scholarship', 'Playgames'];
// const getRandomAdlinksmall = () => adslinksmallArray[Math.floor(Math.random() * adslinksmallArray.length)];const selectAdslinksmall = Math.floor(Math.random() * 3); 
// useEffect(() => {
//     const displayRandomAdlinksmall = () => {
//       const randomAdlinksmall = getRandomAdlinksmall();
//       setActiveAdslinksmall(randomAdlinksmall);
//     };

//     // Display a random tip 30 seconds after the page mounts
//     const displayAdlinksmallTimeout = setTimeout(() => {
//       displayRandomAdlinksmall();
//     }, 30000);

//     // Change the tip every 90 seconds
//     const changeAdlinksmallInterval = setInterval(() => {
//       displayRandomAdlinksmall();
//     }, 20000);

//     // Clear the interval when the component unmounts
//     return () => {
//       clearTimeout(displayAdlinksmallTimeout);
//       clearInterval(changeAdlinksmallInterval);
//     };
//   }, []); // Empty dependency array ensures that this effect runs only once on mount

// let adlinksmallComponent = null;

//   if (activeAdslinksmall === 'Freeairtime') {
//     adlinksmallComponent = <Smalladlink1 closeAdslink={closeAdslinksmall} />;
//   } else if (activeAdslinksmall === 'Scholarship') {
//     adlinksmallComponent = <Smalladlink2 closeAdslink={closeAdslinksmall} />;
//   } else if (activeAdslinksmall === 'Playgames') {
//     adlinksmallComponent = <Smalladlink3 closeAdslink={closeAdslinksmall} />;
//   }

//   const [activeAdslink2, setActiveAdslink2] = useState(null);

// const closeAdslink2 = () => {
//     setActiveAdslink2(false);
// };

// const adslink2Array = ['link1', 'link2', 'link3'];
// const getRandomAdlink2 = () => adslink2Array[Math.floor(Math.random() * adslink2Array.length)];const selectAdslink2 = Math.floor(Math.random() * 3); 
// useEffect(() => {
//     const displayRandomAdlink2 = () => {
//       const randomAdlink2 = getRandomAdlink2();
//       setActiveAdslink2(randomAdlink2);
//     };

//     // Display a random tip 30 seconds after the page mounts
//     const displayAdlink2Timeout = setTimeout(() => {
//       displayRandomAdlink2();
//     }, 30000);

//     // Change the tip every 90 seconds
//     const changeAdlink2Interval = setInterval(() => {
//       displayRandomAdlink2();
//     }, 20000);

//     // Clear the interval when the component unmounts
//     return () => {
//       clearTimeout(displayAdlink2Timeout);
//       clearInterval(changeAdlink2Interval);
//     };
//   }, []); // Empty dependency array ensures that this effect runs only once on mount

// let adlink2Component = null;

//   if (activeAdslink2 === 'link1') {
//     adlink2Component = <Adlink1 closeAdslink={closeAdslink2} />;
//   } else if (activeAdslink2 === 'link2') {
//     adlink2Component = <Adlink2 closeAdslink={closeAdslink2} />;
//   } else if (activeAdslink2 === 'link3') {
//     adlink2Component = <Adlink3 closeAdslink={closeAdslink2} />;
//   }
  const productState = JSON.parse(localStorage.getItem('fullproduct'));
  
  const addWishlist = async () => {
    const accessedToken = JSON.parse(localStorage.getItem('userData'));
    openButtonloading();
    if (!accessedToken) {
      setTimeout(() => {
        navigate('/signin');
      }, 3000);
    } else {
      try {
        const formData = new FormData();
        formData.append('productId', productState._id);

        const response = await axiosInstance.put(WISHLIST_API_KEY, formData);
        closeButtonloading()
        openWishlist();
        setTimeout(() => {
        closeWishlist();
      }, 4000);
      } catch (error){
        if(error.response){
          if (error.response.status === 403) {
            navigate('/signin')
            }else {
          openWishlistfailed();
          setTimeout(() => {
        closeWishlistfailed();
      }, 4000);
        console.error('Error creating your product:', error);
      // Handle error as needed
            }
       }
      }
          }
        };

  
  
  
  const initialSelectedImage = productState ? productState.images[0] : null;
  const [selectedImage, setSelectedImage] = useState(initialSelectedImage);
  
  if (!productState) {
    return (
      <div>
        <h2 className="text-center">{noProduct? "Product not found" : "Product loading. Please wait..."}</h2>
      </div>
    );
  }
  

  return (
    <div className=" w-[100%] pt-[6rem] px-4 bg-[#0C0908]">
      
    <Helmet>
    <meta property="og:url" content={`https://campusify.net/trendingproductpage/${productState.id}/${productState.title || productState.name}`} />
    <meta property="og:title" content={productState.title || productState.name} />
    <meta property="og:description" content={productState.description} />
    <meta property="og:image" content={selectedImage} />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={productState.title || productState.name} />
    <meta name="twitter:description" content={productState.description} />
    <meta name="twitter:image" content={selectedImage} />
  </Helmet>

      <div className="row max-lg:hidden">
      {/* <div style={{zIndex:"7000"}} className='w-[70%] all-round-adslinksmedium max-lg:hidden rounded-lg'>
  {adlinkmediumComponent}
</div>
      <div style={{zIndex:"5000"}} className='w-[70%] all-round-adslinksmedium max-lg:hidden rounded-lg'>
  {adlinksmallComponent}
</div>
      <div style={{zIndex:"5000"}} className='w-[70%] all-round-adslinksmedium max-lg:hidden rounded-lg'>
  {adlink2Component}
</div> */}

        <div className="col-3 flex flex-col gap-2 ">
        <div className="">
            <img src={selectedImage} alt="big_image" width={500} />
            </div>
            <div className=" w-[100%] flex gap-2 ">
          {Object.values(productState.images).map((image, index) => (
            <div  className=""
            key={index}
            style={{
              border: selectedImage === image ? '1px solid #FFD700' : 'transparent',
            }}
          >
            <img
              src={image}
              width={70} 
              alt="main_image"
              className=""
              onClick={() => setSelectedImage(image)}
            />
          </div>
          ))}
        </div> 
        </div>

        <div className="col-5 text-white">
            <div className="my-2">
              <h1 className=" text-2xl p-2">
                <strong>{productState.title || productState.name}</strong>
              </h1>
              <div className="my-2 flex flex-col gap-3 p-2 w-[80%]">
              <div className=""><strong>Description: </strong> {productState.description}</div>
                <div className=""><strong>{productState.title ? "Details: ": "Terms "} </strong> {productState.details || productState.terms}</div>
                <div className=""><strong>{productState.title ? "Brand: ": "Availability "} </strong> {productState.brand || productState.availability}</div>
                <div className=""><strong>{productState.title ? "Condition: ": "Area Coverage "} </strong> {productState.Condition || productState.coverage}</div>
                <div className=""><strong>{productState.title ? "Brand: ": "Availability "} </strong> {productState.brand || productState.availability}</div>
                {productState.title ? (
                  <div className='flex justify-between'>
                <div className=""><strong>Discount Price: </strong> <div className='flex flex-col gap-1'>{productState.promoPrice} <div className='text-[6px] text-gray-400'>{productState.promoQuantity}</div></div></div>
                  <div className='flex gap-2'><strong>Price: </strong><div className="text-2xl flex gap-1 text-[#FFD700]">
                    <TbCurrencyNaira className="naira" />
                    <h3 className="card-price-text text-2xl">{productState.price}</h3>
                </div>
                </div>
                </div>
                ) : (
                  <div className='my-2'>
                  <strong className='my-2'>Our Catalog:</strong> 
                  <div className='flex justify-between'>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className='text-sm'>{productState.service1}</strong> <p className='text-[6px]'>{productState.price1}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className='text-sm'>{productState.service2}</strong> <p className='text-[6px]'>{productState.price2}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className='text-sm'>{productState.service3}</strong> <p className='text-[6px]'>{productState.price3}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className='text-sm'>{productState.service4}</strong> <p className='text-[6px]'>{productState.price4}</p>
                  </div> 
                </div>
                </div>
                )}
                <div className=""><strong>{productState.title ? "Color: ": "Duration "} </strong> {productState.color || productState.duration}</div>
                <div className=""><strong>{productState.title ? "Business Status: ": "Our Location "} </strong> {productState.person || productState.sellerLocation}</div>
                <div className="">{productState.title ? (
                  <div><strong>Fault: </strong> {productState.fault}</div>) : null}</div>   
                
              </div>
          </div>
         
        </div>
        <div className="col-4 flex flex-col gap-4">
        <div className='flex gap-2'>
        <button onClick={addWishlist}  className="btn btn-primary">{buttonloading ? (<div><Buttonloader/></div>) : ('Add Wishlist')}</button>
        <button onClick={openSeller}  className="btn btn-primary">Contact Seller</button>
        <button onClick={openSafety}  className="btn btn-primary">Safety tips</button>
        </div>

          <div className="text-center text-bold text-[#FFD700] text-2xl m-2 p-2">
           <div> More products/services from this seller</div>
        {/* <div  className='w-[100%]'>
        <Sellerproducts className=''/>
      </div> */}
          </div>
        </div>
      </div>


      <div className="container hidden max-lg:block text-[8px]">
        {/* <div style={{zIndex:"7000"}} className='w-[100%] hidden max-lg:block mobile-all-round-adslinksmedium  rounded-lg'>
  {adlinkmediumComponent}
</div>
        <div style={{zIndex:"5000"}} className='w-[100%] hidden max-lg:block mobile-all-round-adslinkssmall  rounded-lg'>
  {adlinksmallComponent}
</div>
        <div style={{zIndex:"5000"}} className='w-[100%] hidden max-lg:block mobile-all-round-adslinks2  rounded-lg'>
  {adlink2Component}
</div> */}
        <div className='flex gap 2 justify-center items-start'>
<div className="w-[45%] flex flex-col gap-2 ">
        <div className="">
            <img src={selectedImage} alt="big_image" width={160} />
            </div>
            <div className=" w-[100%] flex gap-2 ">
          {Object.values(productState.images).map((image, index) => (
            <div  className=""
            key={index}
            style={{
              border: selectedImage === image ? '1px solid #FFD700' : 'transparent',
            }}
          >
            <img
              src={image}
              width={25} 
              alt="main_image"
              className=""
              onClick={() => setSelectedImage(image)}
            />
          </div>
          ))}
        </div> 
        </div>

        <div className="w-[45%] text-white">
            <div className="my-2">
              <h1 className=" text-sm p-2">
                <strong>{productState.title || productState.name}</strong>
              </h1>
              <div className="my-2 flex flex-col gap-3 p-2 w-[80%]">
              <div className=""><strong>Description: </strong> {productState.description}</div>
                <div className=""><strong>{productState.title ? "Details: ": "Terms "} </strong> {productState.details || productState.terms}</div>
                <div className=""><strong>{productState.title ? "Brand: ": "Availability "} </strong> {productState.brand || productState.availability}</div>
                <div className=""><strong>{productState.title ? "Condition: ": "Area Coverage "} </strong> {productState.Condition || productState.coverage}</div>
                <div className=""><strong>{productState.title ? "Brand: ": "Availability "} </strong> {productState.brand || productState.availability}</div>
                {productState.title ? (
                  <div className='flex justify-between'>
                <div className=""><strong>Discount Price: </strong> <div className='flex flex-col gap-1'>{productState.promoPrice} <div className='text-[3px] text-gray-400'>{productState.promoQuantity}</div></div></div>
                  <div className='flex gap-2'><strong>Price: </strong><div className="text-sm flex gap-1 text-[#FFD700]">
                    <TbCurrencyNaira className="naira" />
                    <h3 className="card-price-text text-sm">{productState.price}</h3>
                </div>
                </div>
                </div>
                ) : (
                  <div className='my-2'>
                  <strong className='my-2'>Our Catalog:</strong> 
                  <div className='flex justify-between'>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className=''>{productState.service1}</strong> <p className='text-[3px]'>{productState.price1}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className=''>{productState.service2}</strong> <p className='text-[3px]'>{productState.price2}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className=''>{productState.service3}</strong> <p className='text-[3px]'>{productState.price3}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className=''>{productState.service4}</strong> <p className='text-[3px]'>{productState.price4}</p>
                  </div> 
                </div>
                </div>
                )}
                <div className=""><strong>{productState.title ? "Color: ": "Duration "} </strong> {productState.color || productState.duration}</div>
                <div className=""><strong>{productState.title ? "Business Status: ": "Our Location "} </strong> {productState.person || productState.sellerLocation}</div>
                <div className="">{productState.title ? (
                  <div><strong>Fault: </strong> {productState.fault}</div>) : null}</div>   
                  <div className='flex gap-2 text-[8px]'>
        <button onClick={addWishlist}  className="p-2 bg-[#0d6efd] rounded-sm ">{buttonloading ? (<div><Buttonloader/></div>) : ('Add Wishlist')}</button>
        <button onClick={openSeller}  className="p-2 bg-[#0d6efd] rounded-sm ">Contact Seller</button>
        <button onClick={openSafety}  className="p-2 bg-[#0d6efd] rounded-sm ">Safety tips</button>
        </div>
              </div>
          </div>
         
        </div>
        </div>
        <div className="text-center text-bold text-[#FFD700] text-2xl m-2 p-2">
           <div> More products/services from this seller</div>
        {/* <div  className='w-[100%]'>
        <Sellerproducts className=''/>
      </div> */}
          </div>
         </div>
      {loginModal &&  <div className='w-[60%] rounded-md flex justify-center ' style={{position:'fixed', top:'10%', left:'0', zIndex:'4000',}}> <Loginmodal closeModal={closeLoginModal} closeLoginSuccessModal={closeLoginSuccessModal} openLoginSuccessModal={openLoginSuccessModal}/></div>}
  {loginSuccessModal &&  <div className=' rounded-md flex justify-center ' style={{position:'fixed', top:'10%', left:'0', zIndex:'4000',}}> <Signedinmodal /></div>}
  {loginError &&  <div className='container rounded-md flex justify-center ' style={{position:'fixed', top:'0', right:'20', zIndex:'4000'}}> <Tokenerrormodal /></div>}
      {wishlist && <div style={modals}><Addedtowishlist closeWishlist={closeWishlist}/></div>}
  {wishlistfailed && <div style={modals}><Notaddedtowishlist closeWishlistfailed={closeWishlistfailed}/></div>}
  {message && <div style={modals}><Trendingmessagemodals 
  closeMessage={closeMessage} openMessagenotsent={openMessagenotsent} openMessagesent={openMessagesent}
  closeMessagesent={closeMessagesent} closeMessagenotsent={closeMessagenotsent} closeSeller={closeSeller}
/></div>}
  {callback && <div style={modals}><Callbackmodals 
  closeCallback={closeCallback} openCallbacknotsent={openCallbacknotsent} closeCallbacksent={closeCallbacksent}
  openCallbacksent={openCallbacksent} closeCallbacknotsent={closeCallbacknotsent} closeSeller={closeSeller}
  /></div>}
  {safety && <div style={modals}><Safetytips closeSafety={closeSafety}/></div>}
  {seller && <div style={modals}>
  <ContactSeller closeSeller={closeSeller} openMessage={openMessage} 
  openCallback={openCallback}   
  />
  </div>}
  {messagesent && <div style={modals}><Messagesent closeMessagesent={closeMessagesent}/></div>}
  {messagenotsent && <div style={modals}><Messagenotsent closeMessagenotsent={closeMessagenotsent}/></div>}
  {callbacksent && <div style={modals}><Callbacksent closeCallbacksent={closeCallbacksent}/></div>}
  {callbacknotsent && <div style={modals}><Callbacknotsent closeCallbacknotsent={closeCallbacknotsent}/></div>}
    </div>
  );
};


export const Productpage = () => {
  const {id, title, name} = useParams()
  const navigate= useNavigate();
  const [buttonloading, setButtonloading] = useState(false);
  const [wishlist, setWishlist] = useState(false);
  const [wishlistfailed, setWishlistfailed] = useState(false);
  const [message, setMessage] = useState(false);
  const [callback, setCallback] = useState(false);
  const [safety, setSafety] = useState(false);
  const [seller, setSeller] = useState(false);
  const [messagesent, setMessagesent] = useState(false);
  const [messagenotsent, setMessagenotsent] = useState(false);
  const [callbacksent, setCallbacksent] = useState(false);
  const [callbacknotsent, setCallbacknotsent] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [loginSuccessModal, setLoginSuccessModal] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [noProduct, setNoProduct] = useState(false);
  const [productState, setProductState] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const productId =   JSON.parse(localStorage.getItem('product'));

  

  
  const modals = {
    position: 'fixed',
    top: '10%',
    right: '10%',
    zIndex: '4000',
    width: '40%'
  }

  const openLoginModal= ()=>{
    setLoginModal(true)
  };
  const closeLoginModal= ()=>{
    setLoginModal(false)
  };
  const openLoginError= ()=>{
    setLoginError(true)
  };
  const closeLoginError= ()=>{
    setLoginError(false)
  };
  const openLoginSuccessModal= ()=>{
    setLoginSuccessModal(true)
  };
  const closeLoginSuccessModal= ()=>{
    setLoginSuccessModal(false)
  };

  const GET_PRODUCT_API_KEY = `https://campusbuy.onrender.com/getproduct/${id}`;
  const GET_MYSTORE_API_KEY = 'https://campusbuy.onrender.com/getmystore';

  const openButtonloading = () => {
    setButtonloading(true);
  };
  const closeButtonloading = () => {
    setButtonloading(false);
  };
  const openWishlist = () => {
    setWishlist(true);
  };
  const closeWishlist = () => {
    setWishlist(false);
  };
  const openWishlistfailed = () => {
    setWishlistfailed(true);
  };
  const closeWishlistfailed = () => {
    setWishlistfailed(false);
  };
  const openMessage = () => {
    setMessage(true);
    setCallback(false);
    setSeller(false);
  };
  const closeMessage = () => {
    setMessage(false);
  };
  const openCallback = () => {
    setCallback(true);
  };
  const closeCallback = () => {
    setCallback(false);
  };
  const openSafety = () => {
    setSafety(true);
    setSeller(false)
  };
  const closeSafety = () => {
    setSafety(false);
  };
  const openSeller = () => {
    setSeller(true);
    setSafety(false)
  };
  const closeSeller = () => {
    setSeller(false);
  };
  const openMessagesent = () => {
    setMessagesent(true);
  };
  const closeMessagesent = () => {
    setMessagesent(false);
  };
  const openMessagenotsent = () => {
    setMessagenotsent(true);
  };
  const closeMessagenotsent = () => {
    setMessagenotsent(false);
  };
  const openCallbacksent = () => {
    setCallbacksent(true);
  };
  const closeCallbacksent = () => {
    setCallbacksent(false);
  };
  const openCallbacknotsent = () => {
    setCallbacknotsent(true);
  };
  const closeCallbacknotsent = () => {
    setCallbacknotsent(false);
  };
  useEffect(() => {
    const Reload = async () => {
      try {
        if(id !== productId){
        const response = await axios.get(GET_PRODUCT_API_KEY);
        const productData = response.data;
        setProductState(productData);
        setSelectedImage(productData.images ? productData.images[0] : null);
        localStorage.setItem('mainproduct', JSON.stringify(productData));
        localStorage.setItem('sellerproduct', JSON.stringify(productData));
        const sellerStore = await axios.get(`${GET_MYSTORE_API_KEY}/${response.data.sellerId}`);  
        await localStorage.setItem('sellerstore', JSON.stringify(sellerStore.data.products)); 
        navigate(`/productpage/${id}/${title|| name}`);
        }
      } catch (error) {
        setNoProduct(true)
        console.error('Error fetching product data:', error);
      }
    };
    Reload();
  }, [id, navigate]);

  //  const [Productimage, setProductImage] = useState({
  //   images: [],
  //   title: '',
  //   description: ''
  // });

  // const Getimage = async () => {
  //   try {
  //     const response = await axios.get(GET_PRODUCT_API_KEY);
  //     const productData = response.data;
      
  //     // Store images and other product data in state
  //     setProductImage({
  //       images: productData.images || [],
  //       title: productData.title || title || name,
  //       description: productData.description || 'Buy and sell anything on Campus'
  //     });
      
  //     // Set the first image as selected image
  //     if (productData.images.length > 0) {
  //       setSelectedImage(productData.images[0]);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching product images:', error);
  //   }
  // };

  // // Fetch the product images when the component loads
  // useEffect(() => {
  //   Getimage();
  // }, [id]);
//   const [activeAdslinkmedium, setActiveAdslinkmedium] = useState(null);

//   const closeAdslinkmedium = () => {
//       setActiveAdslinkmedium(false);
//   };
  
//   const adslinkmediumArray = ['Studyabroad', 'Auctionedgoods', 'Boostsales'];
//   const getRandomAdlinkmedium = () => adslinkmediumArray[Math.floor(Math.random() * adslinkmediumArray.length)];const selectAdslinkmedium = Math.floor(Math.random() * 3); 
//   useEffect(() => {
//       const displayRandomAdlinkmedium = () => {
//         const randomAdlinkmedium = getRandomAdlinkmedium();
//         setActiveAdslinkmedium(randomAdlinkmedium);
//       };
  
//       // Display a random tip 30 seconds after the page mounts
//       const displayAdlinkmediumTimeout = setTimeout(() => {
//          displayRandomAdlinkmedium();
//       }, 300000);

  
//       // Change the tip every 90 seconds
//       const changeAdlinkmediumInterval = setInterval(() => {
//         displayRandomAdlinkmedium();
//       }, 90000);
  
//       // Clear the interval when the component unmounts
//       return () => {
//         clearTimeout(displayAdlinkmediumTimeout);
//         clearInterval(changeAdlinkmediumInterval);
//       };
//     }, []); // Empty dependency array ensures that this effect runs only once on mount
  
//   let adlinkmediumComponent = null;
  
//     if (activeAdslinkmedium === 'Studyabroad') {
//       adlinkmediumComponent = <Mediumadlink1 closeAdslink={closeAdslinkmedium} />;
//     } else if (activeAdslinkmedium === 'Auctionedgoods') {
//       adlinkmediumComponent = <Mediumadlink2 closeAdslink={closeAdslinkmedium} />;
//     } else if (activeAdslinkmedium === 'Boostsales') {
//       adlinkmediumComponent = <Mediumadlink2 closeAdslink={closeAdslinkmedium} />;
//     }

//     const [activeAdslinksmall, setActiveAdslinksmall] = useState(null);

// const closeAdslinksmall = () => {
//     setActiveAdslinksmall(false);
// };

// const adslinksmallArray = ['Freeairtime', 'Scholarship', 'Playgames'];
// const getRandomAdlinksmall = () => adslinksmallArray[Math.floor(Math.random() * adslinksmallArray.length)];const selectAdslinksmall = Math.floor(Math.random() * 3); 
// useEffect(() => {
//     const displayRandomAdlinksmall = () => {
//       const randomAdlinksmall = getRandomAdlinksmall();
//       setActiveAdslinksmall(randomAdlinksmall);
//     };

//     // Display a random tip 30 seconds after the page mounts
//     const displayAdlinksmallTimeout = setTimeout(() => {
//       displayRandomAdlinksmall();
//     }, 30000);

//     // Change the tip every 90 seconds
//     const changeAdlinksmallInterval = setInterval(() => {
//       displayRandomAdlinksmall();
//     }, 90000);

//     // Clear the interval when the component unmounts
//     return () => {
//       clearTimeout(displayAdlinksmallTimeout);
//       clearInterval(changeAdlinksmallInterval);
//     };
//   }, []); // Empty dependency array ensures that this effect runs only once on mount

// let adlinksmallComponent = null;

//   if (activeAdslinksmall === 'Freeairtime') {
//     adlinksmallComponent = <Smalladlink1 closeAdslink={closeAdslinksmall} />;
//   } else if (activeAdslinksmall === 'Scholarship') {
//     adlinksmallComponent = <Smalladlink2 closeAdslink={closeAdslinksmall} />;
//   } else if (activeAdslinksmall === 'Playgames') {
//     adlinksmallComponent = <Smalladlink2 closeAdslink={closeAdslinksmall} />;
//   }

//   const [activeAdslink2, setActiveAdslink2] = useState(null);

// const closeAdslink2 = () => {
//     setActiveAdslink2(false);
// };

// const adslink2Array = ['link1', 'link2', 'link3'];
// const getRandomAdlink2 = () => adslink2Array[Math.floor(Math.random() * adslink2Array.length)];const selectAdslink2 = Math.floor(Math.random() * 3); 
// useEffect(() => {
//     const displayRandomAdlink2 = () => {
//       const randomAdlink2 = getRandomAdlink2();
//       setActiveAdslink2(randomAdlink2);
//     };

//     // Display a random tip 30 seconds after the page mounts
//     const displayAdlink2Timeout = setTimeout(() => {
//       displayRandomAdlink2();
//     }, 30000);

//     // Change the tip every 90 seconds
//     const changeAdlink2Interval = setInterval(() => {
//       displayRandomAdlink2();
//     }, 90000);

//     // Clear the interval when the component unmounts
//     return () => {
//       clearTimeout(displayAdlink2Timeout);
//       clearInterval(changeAdlink2Interval);
//     };
//   }, []); // Empty dependency array ensures that this effect runs only once on mount

// let adlink2Component = null;

//   if (activeAdslink2 === 'link1') {
//     adlink2Component = <Adlink1 closeAdslink={closeAdslink2} />;
//   } else if (activeAdslink2 === 'link2') {
//     adlink2Component = <Adlink2 closeAdslink={closeAdslink2} />;
//   } else if (activeAdslink2 === 'link3') {
//     adlink2Component = <Adlink3 closeAdslink={closeAdslink2} />;
//   }

  const accessedToken = JSON.parse(localStorage.getItem('userData'));
  const WISHLIST_API_KEY = 'https://campusbuy.onrender.com/addwishlist';

  const axiosInstance = axios.create({
    baseURL: 'https://campusbuy.onrender.com',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (accessedToken && accessedToken.accessToken) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessedToken.accessToken}`;
  }

  const addWishlist = async () => {
    openButtonloading();
    if (!accessedToken) {
      setTimeout(() => navigate('/signin'), 3000);
    } else {
      try {
        const formData = new FormData();
        formData.append('productId', productState._id);
        const response = await axiosInstance.put(WISHLIST_API_KEY, formData);
        closeButtonloading();
        openWishlist();
        setTimeout(() => {
        closeWishlist();
      }, 4000);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          navigate('/signin');
        } else {
          openWishlistfailed();
          setTimeout(() => {
        closeWishlistfailed();
      }, 4000);
        console.error('Error creating your product:', error);
      // Handle error as needed
            }
        }
      }
  };

  if (!productState) {
    return (
      <div>
        <h2 className="text-center">{noProduct? "Product not found" : "Product loading. Please wait..."}</h2>
      </div>
    );
  }

  return (
    <div className=" w-[100%] pt-[6rem] px-4 bg-[#0C0908]">
      
      <Helmet>
    <meta property="og:url" content={`https://campusify.net/productpage/${productState.id}/${productState.title || productState.name}`} />
    <meta property="og:title" content={productState.title || productState.name} />
    <meta property="og:description" content={productState.description} />
    <meta property="og:image" content={selectedImage} />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={productState.title || productState.name} />
    <meta name="twitter:description" content={productState.description} />
    <meta name="twitter:image" content={selectedImage} />
  </Helmet>

      <div className="row max-lg:hidden">
      {/* <div style={{zIndex:"7000"}} className='w-[70%] all-round-adslinksmedium max-lg:hidden rounded-lg'>
  {adlinkmediumComponent}
</div>
      <div style={{zIndex:"5000"}} className='w-[70%] all-round-adslinkssmall max-lg:hidden rounded-lg'>
  {adlinksmallComponent}
</div>
      <div style={{zIndex:"5000"}} className='w-[70%] all-round-adslinks2 max-lg:hidden rounded-lg'>
  {adlink2Component}
</div> */}

        <div className="col-3 flex flex-col gap-2 ">
        <div className="">
            <img src={selectedImage} alt="big_image" width={500} />
            </div>
            <div className=" w-[100%] flex gap-2 ">
          {Object.values(productState.images).map((image, index) => (
            <div  className=""
            key={index}
            style={{
              border: selectedImage === image ? '1px solid #FFD700' : 'transparent',
            }}
          >
            <img
              src={image}
              width={70} 
              alt="main_image"
              className=""
              onClick={() => setSelectedImage(image)}
            />
          </div>
          ))}
        </div> 
        </div>

        <div className="col-5 text-white">
            <div className="my-2">
              <h1 className=" text-2xl p-2">
                <strong>{productState.title || productState.name}</strong>
              </h1>
              <div className="my-2 flex flex-col gap-3 p-2 w-[80%]">
              <div className=""><strong>Description: </strong> {productState.description}</div>
                <div className=""><strong>{productState.title ? "Details: ": "Terms "} </strong> {productState.details || productState.terms}</div>
                <div className=""><strong>{productState.title ? "Brand: ": "Availability "} </strong> {productState.brand || productState.availability}</div>
                <div className=""><strong>{productState.title ? "Condition: ": "Area Coverage "} </strong> {productState.Condition || productState.coverage}</div>
                <div className=""><strong>{productState.title ? "Brand: ": "Availability "} </strong> {productState.brand || productState.availability}</div>
                {productState.title ? (
                  <div className='flex justify-between'>
                <div className=""><strong>Discount Price: </strong> <div className='flex flex-col gap-1'>{productState.promoPrice} <div className='text-[6px] text-gray-400'>{productState.promoQuantity}</div></div></div>
                  <div className='flex gap-2'><strong>Price: </strong><div className="text-2xl flex gap-1 text-[#FFD700]">
                    <TbCurrencyNaira className="naira" />
                    <h3 className="card-price-text text-2xl">{productState.price}</h3>
                </div>
                </div>
                </div>
                ) : (
                  <div className='my-2'>
                  <strong className='my-2'>Our Catalog:</strong> 
                  <div className='flex justify-between'>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className='text-sm'>{productState.service1}</strong> <p className='text-[6px]'>{productState.price1}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className='text-sm'>{productState.service2}</strong> <p className='text-[6px]'>{productState.price2}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className='text-sm'>{productState.service3}</strong> <p className='text-[6px]'>{productState.price3}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className='text-sm'>{productState.service4}</strong> <p className='text-[6px]'>{productState.price4}</p>
                  </div> 
                </div>
                </div>
                )}
                <div className=""><strong>{productState.title ? "Color: ": "Duration "} </strong> {productState.color || productState.duration}</div>
                <div className=""><strong>{productState.title ? "Business Status: ": "Our Location "} </strong> {productState.person || productState.sellerLocation}</div>
                <div className="">{productState.title ? (
                  <div><strong>Fault: </strong> {productState.fault}</div>) : null}</div>   
                
              </div>
          </div>
         
        </div>
        <div className="col-4 flex flex-col gap-4">
        <div className='flex gap-2'>
        <button onClick={addWishlist}  className="btn btn-primary">{buttonloading ? (<div><Buttonloader/></div>) : ('Add Wishlist')}</button>
        <button onClick={openSeller}  className="btn btn-primary">Contact Seller</button>
        <button onClick={openSafety}  className="btn btn-primary">Safety tips</button>
        </div>

          <div className="text-center text-bold text-[#FFD700] text-2xl m-2 p-2">
           <div> More products/services from this seller</div>
        {/* <div  className='w-[100%]'>
        <Sellerproducts className=''/>
      </div> */}
          </div>
        </div>
          {loginModal &&  <div className='w-[25%] rounded-md flex justify-center ' style={{position:'fixed', top:'10%', left:'0', zIndex:'4000',}}> <Loginmodal closeModal={closeLoginModal} closeLoginSuccessModal={closeLoginSuccessModal} openLoginSuccessModal={openLoginSuccessModal}/></div>}
  {loginSuccessModal &&  <div className=' rounded-md flex justify-center ' style={{position:'fixed', top:'10%', left:'0', zIndex:'4000',}}> <Signedinmodal /></div>}
  {loginError &&  <div className='container rounded-md flex justify-center ' style={{position:'fixed', top:'0', right:'20', zIndex:'4000'}}> <Tokenerrormodal /></div>}
      </div>
      <div className="container hidden max-lg:block text-[8px]">
        {/* <div style={{zIndex:"7000"}} className='w-[100%] hidden max-lg:block mobile-all-round-adslinksmedium  rounded-lg'>
  {adlinkmediumComponent}
</div>
<div style={{zIndex:"5000"}} className='w-[80%] hidden max-lg:block mobile-all-round-adslinkssmall  rounded-lg'>
  {adlinksmallComponent}
</div>
<div style={{zIndex:"5000"}} className='w-[60%] hidden max-lg:block mobile-all-round-adslinks2 rounded-lg'>
  {adlink2Component}
</div> */}
        <div className='flex gap 2 justify-center items-start'>
<div className="w-[45%] flex flex-col gap-2 ">
        <div className="">
            <img src={selectedImage} alt="big_image" width={160} />
            </div>
            <div className=" w-[100%] flex gap-2 ">
          {Object.values(productState.images).map((image, index) => (
            <div  className=""
            key={index}
            style={{
              border: selectedImage === image ? '1px solid #FFD700' : 'transparent',
            }}
          >
            <img
              src={image}
              width={25} 
              alt="main_image"
              className=""
              onClick={() => setSelectedImage(image)}
            />
          </div>
          ))}
        </div> 
        </div>

        <div className="w-[45%] text-white">
            <div className="my-2">
              <h1 className=" text-sm p-2">
                <strong>{productState.title || productState.name}</strong>
              </h1>
              <div className="my-2 flex flex-col gap-3 p-2 w-[80%]">
              <div className=""><strong>Description: </strong> {productState.description}</div>
                <div className=""><strong>{productState.title ? "Details: ": "Terms "} </strong> {productState.details || productState.terms}</div>
                <div className=""><strong>{productState.title ? "Brand: ": "Availability "} </strong> {productState.brand || productState.availability}</div>
                <div className=""><strong>{productState.title ? "Condition: ": "Area Coverage "} </strong> {productState.Condition || productState.coverage}</div>
                <div className=""><strong>{productState.title ? "Brand: ": "Availability "} </strong> {productState.brand || productState.availability}</div>
                {productState.title ? (
                  <div className='flex justify-between'>
                <div className=""><strong>Discount Price: </strong> <div className='flex flex-col gap-1'>{productState.promoPrice} <div className='text-[3px] text-gray-400'>{productState.promoQuantity}</div></div></div>
                  <div className='flex gap-2'><strong>Price: </strong><div className="text-sm flex gap-1 text-[#FFD700]">
                    <TbCurrencyNaira className="naira" />
                    <h3 className="card-price-text text-sm">{productState.price}</h3>
                </div>
                </div>
                </div>
                ) : (
                  <div className='my-2'>
                  <strong className='my-2'>Our Catalog:</strong> 
                  <div className='flex justify-between'>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className=''>{productState.service1}</strong> <p className='text-[3px]'>{productState.price1}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className=''>{productState.service2}</strong> <p className='text-[3px]'>{productState.price2}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className=''>{productState.service3}</strong> <p className='text-[3px]'>{productState.price3}</p>
                  </div>
                <div className="flex flex-col gap-2 w-20%">
                  <strong className=''>{productState.service4}</strong> <p className='text-[3px]'>{productState.price4}</p>
                  </div> 
                </div>
                </div>
                )}
                <div className=""><strong>{productState.title ? "Color: ": "Duration "} </strong> {productState.color || productState.duration}</div>
                <div className=""><strong>{productState.title ? "Business Status: ": "Our Location "} </strong> {productState.person || productState.sellerLocation}</div>
                <div className="">{productState.title ? (
                  <div><strong>Fault: </strong> {productState.fault}</div>) : null}</div>   
                  <div className='flex gap-2 text-[8px]'>
        <button onClick={addWishlist}  className="p-2 bg-[#0d6efd] rounded-sm ">{buttonloading ? (<div><Buttonloader/></div>) : ('Add Wishlist')}</button>
        <button onClick={openSeller}  className="p-2 bg-[#0d6efd] rounded-sm ">Contact Seller</button>
        <button onClick={openSafety}  className="p-2 bg-[#0d6efd] rounded-sm ">Safety tips</button>
        </div>
              </div>
          </div>
         
        </div>
        </div>
        <div className="text-center text-bold text-[#FFD700] text-2xl m-2 p-2">
           <div> More products/services from this seller</div>
        {/* <div  className='w-[100%]'>
        <Sellerproducts className=''/>
      </div> */}
          </div>
          <div className=" w-[100%] text-center text-bold text-[#FFD700] text-2xl  p-2">
      {loginModal &&  <div className='w-[60%] rounded-md flex justify-center ' style={{position:'fixed', top:'10%', left:'0', zIndex:'4000',}}> <Loginmodal closeModal={closeLoginModal} closeLoginSuccessModal={closeLoginSuccessModal} openLoginSuccessModal={openLoginSuccessModal}/></div>}
  {loginSuccessModal &&  <div className=' rounded-md flex justify-center ' style={{position:'fixed', top:'10%', left:'0', zIndex:'4000',}}> <Signedinmodal /></div>}
  {loginError &&  <div className='container rounded-md flex justify-center ' style={{position:'fixed', top:'0', right:'20', zIndex:'4000'}}> <Tokenerrormodal /></div>}
          </div>
         </div>
      {wishlist && <div style={modals}><Addedtowishlist closeWishlist={closeWishlist}/></div>}
  {wishlistfailed && <div style={modals}><Notaddedtowishlist closeWishlistfailed={closeWishlistfailed}/></div>}
  {message && <div style={modals}><Messagemodals 
  closeMessage={closeMessage} openMessagenotsent={openMessagenotsent} openMessagesent={openMessagesent}
  closeMessagesent={closeMessagesent} closeMessagenotsent={closeMessagenotsent} closeSeller={closeSeller}
/></div>}
  {callback && <div style={modals}><Callbackmodals 
  closeCallback={closeCallback} openCallbacknotsent={openCallbacknotsent} closeCallbacksent={closeCallbacksent}
  openCallbacksent={openCallbacksent} closeCallbacknotsent={closeCallbacknotsent} closeSeller={closeSeller}
  /></div>}
  {safety && <div style={modals}><Safetytips closeSafety={closeSafety}/></div>}
  {seller && <div style={modals}>
  <ContactSeller closeSeller={closeSeller} openMessage={openMessage} 
  openCallback={openCallback}   
  />
  {loginModal &&  <div className='w-[60%] rounded-md flex justify-center ' style={{position:'fixed', top:'10%', left:'0', zIndex:'4000',}}> <Loginmodal closeModal={closeLoginModal} closeLoginSuccessModal={closeLoginSuccessModal} openLoginSuccessModal={openLoginSuccessModal}/></div>}
  {loginSuccessModal &&  <div className=' rounded-md flex justify-center ' style={{position:'fixed', top:'10%', left:'0', zIndex:'4000',}}> <Signedinmodal /></div>}
  {loginError &&  <div className='container rounded-md flex justify-center ' style={{position:'fixed', top:'0', right:'20', zIndex:'4000'}}> <Tokenerrormodal /></div>}
  </div>}
  {messagesent && <div style={modals}><Messagesent closeMessagesent={closeMessagesent}/></div>}
  {messagenotsent && <div style={modals}><Messagenotsent closeMessagenotsent={closeMessagenotsent}/></div>}
  {callbacksent && <div style={modals}><Callbacksent closeCallbacksent={closeCallbacksent}/></div>}
  {callbacknotsent && <div style={modals}><Callbacknotsent closeCallbacknotsent={closeCallbacknotsent}/></div>}
    </div>
  );
};