import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useDispatch } from 'react-redux';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { MdLocalShipping } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { TbPremiumRights } from "react-icons/tb";
import { RiAccountPinBoxFill } from "react-icons/ri";
import { IoMdNotifications } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { IoMdCloudDone } from "react-icons/io";
import Select from 'react-select';
import Navbar from './Navbar';
import Loading from 'react-loading';
import { universities } from '../Constants/Universities';
import { Tokenerrormodal } from '../Modals/Forms';
import { IoIosAdd } from "react-icons/io";
import { Noticemodal, GeneralNoticemodal } from './Productmodals';





const Header = () => {
  const [scrollDirection, setScrollDirection] = useState('up');
const [prevScrollPos, setPrevScrollPos] = useState(0);
const [signin, setSignin] = useState(false);
const [loading, setLoading] = useState(false);
const navigate = useNavigate();
const dispatch = useDispatch();
// Move the declaration of 'university' above its usage
const accessedToken = JSON.parse(localStorage.getItem('userData'));
const Messagenotice = JSON.parse(localStorage.getItem('unreadmessage'));
const Messagesnotice = JSON.parse(localStorage.getItem('messagenotice'));
const unreadcallback = JSON.parse(localStorage.getItem("unreadcallback"));
const unreadnotification = JSON.parse(localStorage.getItem("unreadnotification"));
 
  const openSignin = ()=>{
    setSignin(true)
  }
  const closeSignin = ()=>{
    setSignin(false)
  }
  const openLoading = ()=>{
    setLoading(true)
  }
  const closeLoading = ()=>{
    setLoading(false)
  }

let university = JSON.parse(localStorage.getItem("universities"));

  if (!university) {
      university = {
          label: "University of Benin, Benin-city",
          value: "University of Benin, Benin-city"
      };
      localStorage.setItem("universities", JSON.stringify(university));
  }

  const axiosInstance = axios.create({
    baseURL: 'https://campusbuy.onrender.com', // Replace with your API base URL
    headers: {
      'Content-Type': 'application/json',
      // Other default headers can be added here
    },
  });
  
  // Function to set JWT token in the headers
  const setAuthToken = (token) => {
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  };
  
  // Use this function to set the token when the user logs in or the token is available
  if (accessedToken && accessedToken.accessToken){
    setAuthToken(accessedToken.accessToken);
  }  
  // Now, you can use axiosInstance for your requests with the token included in the headers
  
const GET_WISHLIST_API_KEY= "https://campusbuy.onrender.com/getwishlist";  
const GET_NOTIFICATIONS_API_KEY= "https://campusbuy.onrender.com/getnotification";
const GET_MESSAGES_API_KEY= "https://campusbuy.onrender.com/getconversations";
const GET_CALLBACK_API_KEY= "https://campusbuy.onrender.com/getcallback";
const GET_YOURPRODUCTS_API_KEY= "https://campusbuy.onrender.com/getyourproduct";  
  
useEffect(() => {
  const getApis = async () => {
    try {
      if (accessedToken && accessedToken.accessToken) {
        const yourReferrals = await axiosInstance.get("https://campusbuy.onrender.com/getyourreferrals");
        localStorage.setItem('referrals', JSON.stringify(yourReferrals.data));
      }
    } catch (error) {
      console.error('Error getting APIs:', error);
    }
  };
  getApis();
}, []);  

  


  const handleScroll = () => {
    const currentScrollPos = document.documentElement.scrollTop;

    if (currentScrollPos > prevScrollPos) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);


  const postProduct =async ()=> {
    if(accessedToken){
      navigate('/createproductpage')
    }else{
      await openSignin();
      setTimeout(() => {
      navigate('/signin')
      closeSignin();
      }, 3000);
    }

  }

  
 
 

   const Signout= async() => {
    try{
     await localStorage.removeItem('userData');
     await localStorage.removeItem('wishlistcard');
     await localStorage.removeItem('notificationscard');
     await localStorage.removeItem('yourproductscard');
     await localStorage.removeItem('conversationscard');
     await localStorage.removeItem('newmessage');
     await localStorage.removeItem('callbackcard');
     await localStorage.removeItem('usercard');
     
      navigate('/');
}catch (error){
      console.error('Error signing out Account:', error);
    }
    
  }

  const loginOrOut = () =>{
    if(accessedToken){
      Signout();
  }else{
    navigate('/signin')
  }
}



const loggedinOrOut = async (API, store) => {
  try {
    if (accessedToken) {
      const response = await axiosInstance.get(API);
      await localStorage.setItem(store, JSON.stringify(response.data));
      
      // Check if 'id' property exists in accessedToken before navigating
      if (accessedToken.id ? accessedToken.id : accessedToken._id) {
        navigate(`/mainpage/${accessedToken.id ? accessedToken.id : accessedToken._id}`);
      } else {
        console.error('Error navigating to Your Account: Invalid accessedToken');
      }
    } else {
      await openSignin();
      setTimeout(() => {
      navigate('/signin')
      closeSignin();
      }, 3000);
    }
  } catch (error) {
    console.error('Error navigating to Your Account:', error);
    if (error.response && error.response.status === 403) {
      await openSignin();
      setTimeout(() => {
      navigate('/signin')
      closeSignin();
      }, 3000);
    }else{
      alert('Connection error. Please refresh your network')
    }
  }
};



const accountNavigate = async () => {
  try {
    if (accessedToken) {
      // Check if 'id' property exists in accessedToken before navigating
      if (accessedToken.id ? accessedToken.id : accessedToken._id) {
        navigate(`/mainpage/${accessedToken.id ? accessedToken.id : accessedToken._id}`);
      } else {
        console.error('Error navigating to Your Account: Invalid accessedToken');
      }
    } else {
      navigate('/signin');
    }
  } catch (error) {
    console.error('Error navigating to Your Account:', error);
  }
};


 const [selectedOption, setSelectedOption] = useState(null);

useEffect(() => {
  if (selectedOption) {
    trendingProducts();
  }
}, [selectedOption]);
useEffect(() => {
  const Getsearchinputs= async()=> {
    const response = await axios.get("https://campusbuy.onrender.com/getsearchinputs");
      localStorage.setItem('searchinputs', JSON.stringify(response.data));
  }
  Getsearchinputs();
}, []);

const trendingProducts = async () => {
  try {
    const TRENDING_PRODUCTS_API_KEY = `https://campusbuy.onrender.com/getproducts?university=${selectedOption.value}&premiumServices=true`;

    const response = await axios.get(TRENDING_PRODUCTS_API_KEY);
    
    if (response.data) {
      localStorage.setItem('trendingproducts', JSON.stringify(response.data.data));
       window.location.reload(); // Reload the page after updating localStorage
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      localStorage.removeItem('trendingproducts');
    } else {
      console.error('Error fetching trending products:', error);
    }
  }
};

const changeSchool = (selectedOption) => {
  if (selectedOption) {
    setSelectedOption(selectedOption);
    localStorage.setItem("universities", JSON.stringify(selectedOption));
  } else {
    localStorage.setItem("universities", JSON.stringify({label:"University of Benin, Benin-city", value:"University of Benin, Benin-city"}));
  }
};

const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: '#FEBD69',
    fontWeight: 'bold',
  }),
  control: (provided) => ({
    ...provided,
    borderColor: 'black',
    borderWidth: 2,
    borderRadius: '0.375rem', // rounded-md equivalent
  }),
};
  const sendWishlist = (activePage) => dispatch({ type: 'GET_ACTIVEPAGE', activePage });
  const sendNotifications = (activePage) => dispatch({ type: 'GET_ACTIVEPAGE', activePage });
  const sendAccount = (activePage) => dispatch({ type: 'GET_ACTIVEPAGE', activePage });
  const sendMessages = (activePage) => dispatch({ type: 'GET_ACTIVEPAGE', activePage });
  const sendYourproducts = (activePage) => dispatch({ type: 'GET_ACTIVEPAGE', activePage });
  const sendCallbacks = (activePage) => dispatch({ type: 'GET_ACTIVEPAGE', activePage });
  const sendMystore = (activePage) => dispatch({ type: 'GET_ACTIVEPAGE', activePage });

  
  
  return (
    <>
    <header className='w-[100%] header-top-strip  max-lg:hidden'>
      <div className='container-xxl'>
        <div className='flex justify-between items-center py-2'>
          <div className='flex text-[10px] text-[#FEBD69] text-bold gap-[2rem] mx-[1.5rem] items-center '>
            <button onClick={postProduct} className ='flex gap-1 justify-center items-center bg-black p-1.5 rounded-md sell-something-button'>
            <span>Sell on Campus</span> <IoIosAdd className='header-react-icons-add' /></button>
            <div className=' text-center'>
            <NavLink to= "">
            <span>Logistics</span></NavLink>
          </div>
          <div className=' text-center'>
            <NavLink to='/'>
              <span>  Excro Payment</span></NavLink>
          </div>
          <div className=' text-center'>
            <NavLink to='/'>
             <span>  Premium Services</span></NavLink>
          </div>
          </div>
          <div className='col-md-3 search-for-schools '>
          <div className="search-container flex w-[100%]">
          <Select
  className='w-[100%] rounded-md border-2 border-black'
  value={selectedOption? selectedOption : university.value}
  onChange={changeSchool}
  options={universities.map((university) => ({
    value: university.title,
    label: university.title,
  }))}
  placeholder={selectedOption? selectedOption : university.value}
    isClearable 
    styles={customStyles}
/>

</div>
          </div>
        </div>
      </div>
    </header>
    <header className={`header-upper w-[100%] pt-2 pb-1 text-[12px] max-lg:hidden ${scrollDirection === 'down' ? 'scrolled-down' : 'scrolled-up'}`}>
      <div className='flex justify-between items-center px-4'>
        <div>
          <Link to='/'><img className='border border-[#FFD700] rounded-[50%]' src='https://res.cloudinary.com/dneejvhch/image/upload/v1725437388/Campusbuy/CC_20240904_090628_xz8yqx.png' width={40} alt='logo'/></Link>
        </div>
        <div className='flex justify-center gap-[1.5rem]'>
      <div>
        <NavLink to='/'><span  className=' text-white'>Home</span></NavLink>
      </div>
      <div>
        <NavLink to='/about'><span  className=' text-white'>About us</span></NavLink>
      </div>
      <div>
        <NavLink to='/contact'><span  className=' text-white'>Contact</span></NavLink>
      </div>
      <div>
        <NavLink to='/faqs'><span  className=' text-white'>FAQs</span></NavLink>
      </div>
    </div>
   <div className='flex justify-center items-center gap-[1.5rem]'>
   <div className='col-3'>
       <a
           className=' flex justify-center items-center'
            onClick={() =>{ loggedinOrOut(GET_NOTIFICATIONS_API_KEY, "notificationscard")
             sendNotifications('Notifications')
            }}
            >
           <IoMdNotifications className='header-react-icons' />
         </a>
       </div>
       <div className= 'dropdown mt-[-0.38rem]'>
        <button
          className=' btn btn-secondary dropdown-toggle  dropdown-toggle-no-caret border-none'
          type='button'
          id='dropdownMenuButton1'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <div className='flex  flex-col gap-1 justify-center items-center'>
            <RiAccountPinBoxFill className='mobile-header-react-icons' />
          </div>
          
        </button>
        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
          <li>
            <a
              className='dropdown-item text-[12px]'
              
              onClick={() => {
                accountNavigate();
                sendAccount('Account');
              }}
              >
              Account
            </a>
          </li>
          <li>
            <a
              className='dropdown-item text-[12px]'
              
              onClick={() => { loggedinOrOut(GET_MESSAGES_API_KEY, "conversationscard") 
              sendMessages('Messages')}}
            >
              Messages
            </a>
            {Messagesnotice ? <div style={{ position: 'absolute', top: '20%', right: '15%' }}>
      <Noticemodal/></div> : null}
          </li>
          <li>
            <a
              className='dropdown-item text-[12px]'
              
              onClick={() => { loggedinOrOut(GET_YOURPRODUCTS_API_KEY, "yourproductscard") 
              sendYourproducts('Yourproducts')}}
            >
              My Products
            </a>
          </li>
          <li>
            <a
              className='dropdown-item text-[12px]'
              
              onClick={() => {  loggedinOrOut(GET_CALLBACK_API_KEY, "callbackcard") 
              sendCallbacks('Callbacks')}}
            >
              Callbacks
            </a>
          </li>
          <li>
            <a
              className='dropdown-item text-[12px]'
              
              onClick={() => {  loggedinOrOut(GET_WISHLIST_API_KEY, "wishlistcard") 
            sendWishlist('Wishlist')}}
            >
            Wishlist
            </a>
          </li>
          
          <li>
            <a
              className='dropdown-item text-[12px]'
              
              onClick={() => { loggedinOrOut(GET_YOURPRODUCTS_API_KEY, "yourproductscard") 
                sendMystore   ('Mystore')}}
            >
              My Store
            </a>
          </li>
          
        </ul>
      </div>
            
      <div>
      <a onClick={loginOrOut} className='dropdown-item py-2 p-3 rounded-[40px] bg-[#FEBD69] text-bold '>
             {accessedToken ? 'Sign Out' : 'Sign In'}
         </a>
       </div>
      </div>
      </div>
      </header>
    
   <header className='header-mobile  hidden max-lg:block'>
    <Navbar/>
    </header>  
    {signin && <div className='  border p-4'><Tokenerrormodal/></div>}
    <div  style={{position: "fixed", top: "50%", left:"50%"}}>
 {loading && <div className='loading-modal flex flex-col justify-center items-center'><Loading type="spin" color="#FFFFFF" height={40} width={40} />
    <p style={{ color: 'white', marginTop: '10px' }}>Please wait...</p>
  </div>}
</div>
    
    </>
  )
}

export default Header



 

        

